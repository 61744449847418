<template>
	<div>
		<template>
			<div id="breadcrumb-alignment" class="mb-1">
				<div class="d-flex justify-content-start breadcrumb-wrapper">
					<b-breadcrumb
					:items="breadCrumb()"
					/>
				</div>
			</div>
		</template>

		<b-card>

			<b-row v-if="checkPermission('custom-forms', 'Update Item')">
				<b-col md=12>
					<div class="demo-inline-spacing float-right ">
						<b-button variant="danger" class="mt-0 mb-1" @click="openLocations()"  v-if="disabledOptions == false">
							<feather-icon icon="TargetIcon" class="mr-25"/>
							<span>Locations</span>
						</b-button>
					</div>
				</b-col>
			</b-row>

			<b-alert v-model="showDismissibleAlert" variant="danger" dismissible class="mb-1">
				<div class="alert-body">
					{{error_message}}
				</div>
			</b-alert>

			<b-row>
				<b-col md="12">
					<h4 class="card-title float-left"> Marker Pens</h4>
				</b-col>
			</b-row>

			<b-row>

				<b-col md="3">
					<b-form-group label="Project Site" class="required">
						<b-form-select v-model="site" @change="siteChange" :disabled="disabledOptions">
							<b-form-select-option value="" disabled>Select</b-form-select-option>
							<b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{site.site_name | capitalize}}</b-form-select-option>
						</b-form-select>
					</b-form-group>
				</b-col>

				<b-col md="3">
					<b-form-group label="Shift" class="required">
						<b-form-select v-model="shift" @change="getLocationData()" :disabled="disabledOptions">
							<b-form-select-option value="" disabled>Select</b-form-select-option>
							<b-form-select-option :value="shift._id" v-for="shift in shifts" :key="shift._id">{{shift.shift | capitalize}}</b-form-select-option>
						</b-form-select>
					</b-form-group>
				</b-col>

				<b-col md="3" >
					<b-form-group label="Date" class="required"> 
						<b-form-datepicker
							v-model="date"
							:date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }"
							locale="en"
							@input="getLocationData()"
							:disabled="disabledOptions"
						/>
					</b-form-group>
				</b-col>

				<b-col md="3" >
					<b-form-group label="Checked By" class="required">
						<b-form-input placeholder="3 to 50 characters" v-model="checked_by" :disabled="disabledOptions"/>
					</b-form-group>
				</b-col>

			</b-row>
		
		</b-card>
		
		<b-form @submit="formSubmit">
			<div v-if="site != '' && shift != ''">
			<b-card no-body class="mb-1" v-for="(list,i) in checklistData" :key="i">

				<!-- <b-row v-if="site">
					<b-col md="3" class="ml-1 p-1">
						<b-form-group label="Building" class="required">
							<b-form-select v-model="list.building" @change="buildingChange(list.building,i)">
								<b-form-select-option value="">Select Building</b-form-select-option>
								<b-form-select-option :value="building._id" v-for="building in buildings" :key="building._id" :disabled="buildingDisable(building._id)">{{building.name | capitalize}}</b-form-select-option>
							</b-form-select>
						</b-form-group>
					</b-col>
				</b-row> -->

				<b-table 
					responsive
					show-empty
					ref = "refUserListTable"
					class = "position-relative"
					primary-key = "id"
					empty-text = "No records found"
					:fields = "tableColumns"
					:items = "list.data"
					v-if="site != ''"
				>

					<template slot="thead-top" slot-scope="data">
						<tr>
							<th colspan="2">
								<b-form-select v-model="list.building" @change="buildingChange(list.building,i)" :disabled="(checkdisabled() && (checklistData.length > 0))">>
									<b-form-select-option value="" disabled>Select Building</b-form-select-option>
									<b-form-select-option :value="building._id" v-for="building in buildings" :key="building._id" :disabled="buildingDisable(building._id)">{{building.name | capitalize}}</b-form-select-option>
								</b-form-select>
							</th>
							<th colspan="4" style="background-color: #f5c6cb;color:black;text-align:center;">
								<span class="h4">Marker Pen</span> <br> (Marker pen must check one by one)
							</th>
							<th colspan="4">
								<!-- <b-button size="sm" variant="danger" class="mr-1 float-right" @click="removeData(i)" v-if="checklistData.length > 0 && checklistData[0].data.length > 0">Remove</b-button> -->
								<b-button
									size="sm"
									variant="danger"
									class="mr-1 float-right"
									@click="removeData(i)"
									v-if="checklistData.length > 0 && checklistData[0].data.length > 0 && checklistData[i].db_building == false"
									:disabled="checkdisabled()">
									Remove
								</b-button>

								<b-button
									size="sm"
									variant="danger"
									class="mr-1 float-right"
									@click="removeDBBuilding(i,checklistData[i])"
									v-if="checklistData.length > 0 && checklistData[0].data.length > 0 && checklistData[i].db_building == true"
									:disabled="checkdisabled()">
									Remove
								</b-button>
							</th>
						</tr>
					</template>

					<template #cell(level)="items">
						<p class="wordBreak">{{items.item.level}}</p>
					</template>

					<template #cell(location)="items">
						<p class="wordBreak">{{items.item.location}}</p>
					</template>

					<template #cell(red)="items">
						<b-form-checkbox v-if="!items.item.red_input_visible" v-model="items.item.red" value="yes" unchecked-value="no" class="custom-control-danger" :disabled="items.item.red_disable" @input="items.item.row_modify = true; disableCheckbox(items.index,null,i,null)" />
						<b-form-input v-if="items.item.red_input_visible" v-model="items.item.red_input" autocomplete="off" @keypress="isNumber($event)" @input="totalRed(); items.item.row_modify = true; disableCheckbox(items.index,null,i,null)"/>
						<feather-icon v-if="!items.item.red_input_visible" :icon="items.item.red_disable == true ? 'EyeIcon' : 'EyeOffIcon'" class="mt-1 mr-1" @click="disableCheckbox(items.index,'on',i,'red')"/>
						<feather-icon :icon="items.item.red_input_visible == true ? 'SquareIcon' : 'EditIcon'" class="mt-1" @click="inputVisibility(items.index,i,'red_input'); clearChanged(items.index, i)" />
					</template>

					<template #cell(blue)="items">
						<b-form-checkbox v-if="!items.item.blue_input_visible" v-model="items.item.blue" value="yes" unchecked-value="no" class="custom-control-primary" :disabled="items.item.blue_disable" @input="items.item.row_modify = true; disableCheckbox(items.index,null,i,null)"/>
						<b-form-input v-if="items.item.blue_input_visible" v-model="items.item.blue_input" autocomplete="off" @keypress="isNumber($event)" @input="totalBlue(); items.item.row_modify = true; disableCheckbox(items.index,null,i,null)"/>
						<feather-icon v-if="!items.item.blue_input_visible" :icon="items.item.blue_disable == true ? 'EyeIcon' : 'EyeOffIcon'" class="mt-1 mr-1" @click="disableCheckbox(items.index,'on',i,'blue')"/>
						<feather-icon :icon="items.item.blue_input_visible == true ? 'SquareIcon' : 'EditIcon'" class="mt-1" @click="inputVisibility(items.index,i,'blue_input'); clearChanged(items.index, i)" />
					</template>

					<template #cell(green)="items">
						<b-form-checkbox v-if="!items.item.green_input_visible" v-model="items.item.green" value="yes" unchecked-value="no" class="custom-control-success" :disabled="items.item.green_disable" @input="items.item.row_modify = true; disableCheckbox(items.index,null,i,null)"/>
						<b-form-input v-if="items.item.green_input_visible" v-model="items.item.green_input" autocomplete="off" @keypress="isNumber($event)" @input="totalGreen(); items.item.row_modify = true; disableCheckbox(items.index,null,i,null)"/>
						<feather-icon v-if="!items.item.green_input_visible" :icon="items.item.green_disable == true ? 'EyeIcon' : 'EyeOffIcon'" class="mt-1 mr-1" @click="disableCheckbox(items.index,'on',i,'green')"/>
						<feather-icon :icon="items.item.green_input_visible == true ? 'SquareIcon' : 'EditIcon'" class="mt-1" @click="inputVisibility(items.index,i,'green_input'); clearChanged(items.index, i)" />
					</template>

					<template #cell(black)="items">
						<b-form-checkbox v-if="!items.item.black_input_visible" v-model="items.item.black" value="yes" unchecked-value="no" class="custom-control-secondary" :disabled="items.item.black_disable" @input="items.item.row_modify = true; disableCheckbox(items.index,null,i,null)"/>
						<b-form-input v-if="items.item.black_input_visible" v-model="items.item.black_input" autocomplete="off" @keypress="isNumber($event)" @input="totalBlack(); items.item.row_modify = true; disableCheckbox(items.index,null,i,null)"/>
						<feather-icon v-if="!items.item.black_input_visible" :icon="items.item.black_disable == true ? 'EyeIcon' : 'EyeOffIcon'" class="mt-1 mr-1" @click="disableCheckbox(items.index,'on',i,'black')"/>
						<feather-icon :icon="items.item.black_input_visible == true ? 'SquareIcon' : 'EditIcon'" class="mt-1" @click="inputVisibility(items.index,i,'black_input'); clearChanged(items.index, i)" />
					</template>

					<template #cell(duster)="items">
						<b-form-checkbox v-if="!items.item.duster_input_visible" v-model="items.item.duster" value="yes" unchecked-value="no" class="custom-control-warning" :disabled="items.item.duster_disable" @input="items.item.row_modify = true; disableCheckbox(items.index,null,i,null)"/>
						<b-form-input v-if="items.item.duster_input_visible" v-model="items.item.duster_input" autocomplete="off" @keypress="isNumber($event)" @input="totalDuster(); items.item.row_modify = true; disableCheckbox(items.index,null,i,null)"/>
						<feather-icon v-if="!items.item.duster_input_visible" :icon="items.item.duster_disable == true ? 'EyeIcon' : 'EyeOffIcon'" class="mt-1 mr-1" @click="disableCheckbox(items.index,'on',i,'duster')"/>
						<feather-icon :icon="items.item.duster_input_visible == true ? 'SquareIcon' : 'EditIcon'" class="mt-1" @click="inputVisibility(items.index,i,'duster_input'); clearChanged(items.index, i)" />
					</template>

					<template #cell(remarks)="items">
						<b-form-input placeholder="" v-model="items.item.remarks" autocomplete="off" @input="items.item.row_modify = true"/>
					</template>

				</b-table>

			</b-card>

			<b-card no-body class="mb-1" >
      
				<b-table
					show-empty
					empty-text = "No records found"
					:fields = "tableColumns2"
					:items = "items2"
					ref = "totalTable"
					v-if="checklistData.length > 0 && checklistData[0].data.length > 0"
				>
					<template #cell(level)="items2">
						<p>{{items2.item.building_name}}</p>
					</template>   

					<template #cell(total_red)="items2">
						<p>{{items2.item.total_red}}</p>
					</template>

					<template #cell(total_blue)="items2">
						<p>{{items2.item.total_blue}}</p>
					</template>

					<template #cell(total_green)="items2">
						<p>{{items2.item.total_green}}</p>
					</template>

					<template #cell(total_black)="items2">
						<p>{{items2.item.total_black}}</p>
					</template>

					<template #cell(total_duster)="items2">
						<p>{{items2.item.total_duster}}</p>
					</template>
				</b-table>

			</b-card>
			</div>

			<b-card class="mt-1" v-if="checklistData.length > 0 && checklistData[0].data.length > 0">
				<b-row class="p-2">
					<b-col offset-md="3" md="3">
						<b-form-group label="Start Time" class="required">
							<flat-pickr
								v-model="start_time"
								class="form-control"
								:config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i'}"
							/>
						</b-form-group>
					</b-col>
					<b-col md="3">
						<b-form-group label="End Time" class="required">
							<flat-pickr
								v-model="end_time"
								class="form-control"
								:config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i'}"
							/>
						</b-form-group>
					</b-col>
					<b-col md="3">
						<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="warning" class="btn mr-1 float-right mt-2 btn-warning" @click="addMore" v-if="site != ''" :disabled="checkdisabled()" >
							Add More
						</b-button>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols="12">
						<SupervisorSignature v-bind:tindex="1" v-bind:index="1" v-bind:signature="supervisor_signature" :disabledOptions="disabledOptions"/>	
					</b-col>
				</b-row>	
			</b-card>

			<b-card class="mt-1" v-if="checklistData.length > 0 && checklistData[0].data.length > 0">
				
				<b-row>
					<b-col>

						<b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" class = "mr-1" variant="outline-secondary" @click="goBack()">
							Cancel
						</b-button>

						<b-button v-ripple.400 = "'rgba(255, 255, 255, 0.15)'" variant = "danger" class = "mr-1" @click="discardItem()" v-if="id != null && typeof $route.params.id == 'undefined'">
							Discard Draft
						</b-button>

						<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-1" type="submit" @click="status = 'draft'" v-if="typeof $route.params.id == 'undefined'">
	         			   {{ id == null ? 'Save as Draft' : 'Update Draft'}}
						</b-button>

						<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" class="mr-1" type="submit" @click="status = 'completed'">
            				{{ typeof $route.params.id == 'undefined' ? 'Submit' : 'Save' }}
						</b-button>

			<b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            v-if="typeof $route.params.id != 'undefined' && ledit == false"
            @click="openSignature('approver')"
          >
            {{ typeof $route.params.id == 'undefined' ? 'Submit' : 'Approve' }}
          </b-button>

					</b-col>
				</b-row>

			</b-card>

		</b-form>

				    <b-modal
        id="signatureModel"
        ref="signatureModel"
        centered
        title="Signature"
        no-close-on-backdrop
        hide-footer
        @hide="hideSignature"
        @show="hideSignature"
      >
      
      <div v-if="show_default == true">
        <b-row>

          <b-col md="11" style="background: #ffffff; height:300px; vertical-align: middle;margin-left: 4%;margin-right:2%">
            <center>
              <img :src="tempSign.image" width="240px" height="200px" style="background: #ffffff; border-radius: 4px;margin-top: 11%">
            </center>

          </b-col>
        </b-row>
        <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined'">

          <b-col md="12">
            <date-picker v-model="approve_signature_time" 
              format="DD MMM YYYY HH:mm" 
              type="datetime"
              placeholder="Select"
              valueType="format"
              :clearable=false
              :confirm=true
              lang="en"
              :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkDatePicker' : 'lightDatePicker'"
              :disabled="$store.getters.currentUser.role == 'supervisor' || $store.getters.currentUser.role == 'operation_manager' || $store.getters.currentUser.role == 'admin' ? false : true"
              :disabled-date="disabledDate"
              ></date-picker>


          </b-col>
                  </b-row>

		  <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined'">
			<b-col md="12">
				<b-form-group
					label="Remarks"
					class=""
				>
					<b-form-textarea
					placeholder=""
					rows="3"
					v-model="approve_remark"
					/>
				</b-form-group>
			</b-col>
		  </b-row>

          <b-row class="mt-2">
          <b-col md="6">

              <b-button
                  variant="secondary"
                  class="mt-0 w-100"
                  @click="show_default = false"
                >
                <span class="text-nowrap">Add New Signature</span>
              </b-button>
            </b-col>

            <b-col md="6">
              <b-button
                  variant="warning"
                  class="mt-0 float-right w-100"
                  @click="saveDefault()"
                >
                <span class="text-nowrap">Submit</span>
              </b-button>
            </b-col>
        </b-row>
      </div>

      <div v-else>
        <b-row>
          <b-col md="12">
            <VueSignaturePad
              id="signature"
              width="100%"
              height="300px"
              ref="signaturePad"
              :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()},penColor: '#000000',minWidth: 3,maxWidth: 3}"
            />

          </b-col>
        </b-row>
          <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined'">
          
          <b-col md="12">
            <date-picker v-model="approve_signature_time" 
              format="DD MMM YYYY HH:mm" 
              type="datetime"
              placeholder="Select"
              valueType="format"
              :clearable=false
              :confirm=true
              lang="en"
              :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkDatePicker' : 'lightDatePicker'"
              :disabled="$store.getters.currentUser.role == 'supervisor' || $store.getters.currentUser.role == 'operation_manager' || $store.getters.currentUser.role == 'admin' ? false : true"
              :disabled-date="disabledDate"
              ></date-picker>


          </b-col>
        </b-row>

		<b-row class="mt-2" v-if="typeof $route.params.id != 'undefined'">
          
          <b-col md="12">
            <b-form-group
                label="Remarks"
                class=""
              >
                <b-form-textarea
                  placeholder=""
                  rows="3"
                  v-model="approve_remark"
                />
              </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-2">

          <b-col md="3" v-if="typeof $route.params.id == 'undefined'">
            <b-button
                variant="primary"
                class="mt-0 w-100"
                @click="uploadSign()"
              >
              <span class="text-nowrap">Upload</span>
            </b-button>
          </b-col>

          <b-col :md="typeof $route.params.id != 'undefined' ? '4' : '3'">

              <b-button
                  variant="secondary"
                  class="mt-0 w-100"
                  v-if="having_default == true"
                  @click="show_default = true"
                >
                <span class="text-nowrap">Default</span>
              </b-button>
            </b-col>


          <b-col :md="typeof $route.params.id != 'undefined' ? '4' : '3'">

              <b-button
                  variant="danger"
                  class="mt-0 w-100"
                  @click="clearSignature"
                >
                <span class="text-nowrap">Clear</span>
              </b-button>
            </b-col>

            <b-col :md="typeof $route.params.id != 'undefined' ? '4' : '3'">
              <b-button
                  variant="warning"
                  class="mt-0 w-100"
                  @click="saveSignature"
                >
                <span class="text-nowrap">Submit</span>
              </b-button>
            </b-col>
        </b-row>
      </div>
      </b-modal> 


	</div>
</template>

<script>
	import {
		BCard, BButton, BAvatar, BRow, BCol, BAlert, BTable, BMedia, BForm, BFormTextarea, BModal, BBadge, 
		BCardText, BFormInput, BFormGroup, BFormSelect, BFormSelectOption, BFormCheckbox, BFormDatepicker, BBreadcrumb
	} from 'bootstrap-vue';
	import { avatarText } from '@core/utils/filter';
	import Ripple from 'vue-ripple-directive';
	import { GET_API, POST_API } from "../../../store/actions.type";
	import 'viewerjs/dist/viewer.css';
	import Datepicker from 'vuejs-datepicker';
	import flatPickr from 'vue-flatpickr-component';
	import vSelect from 'vue-select';
	import SupervisorSignature from './SupervisorSignature.vue';
	import Bus from "../../../event-bus";
	var moment = require('moment-timezone');
	import DatePicker from 'vue2-datepicker';
	import 'vue2-datepicker/index.css';

	export default {
		components: {
			BCard, BButton, BRow, BCol, BAvatar, BAlert, BTable, BMedia, BForm, BFormTextarea, 
			BModal, BBadge, BCardText, BFormInput, BFormGroup, BFormSelect, BFormSelectOption,
			Datepicker, BFormCheckbox, flatPickr, vSelect, BFormDatepicker, SupervisorSignature, BBreadcrumb, DatePicker
		},

		data() {
			return {
				date:moment().tz('Asia/Singapore').format('YYYY-MM-DD'),
				id: null,
				site:'',
				checked_by:this.$store.getters.currentUser.full_name,
				shift:'',
				building:'',
				buildings:[],
				sites:[],
				shifts:[],
				items2:[{building_name: '', total_red: 0, total_blue: 0, total_green: 0, total_black: 0,total_duster: 0}],

				supervisor_signature:{
					image:null,
					name:'',
					default:'no'
				},
				start_time: null,
				end_time: null,
				// remarks:'',

				showDismissibleAlert:false,
				error_message:'',
				doc_icon: require('@/assets/images/doc.png'),
				checklistData:[],
				status:'active',
				
				openedSignModel:null,

				supervisorSign : {
            		image:null,
            		name:'',
           			type: 'supervisor',
           			default:'no'
      			},
				show_default:false,
      			having_default:false,
				disabledOptions:false,
      			approve_signature_time:null,
				approve_remark:'',
      			ledit : false,

				tableColumns:[
					{ key: 'level', label: 'Level', sortable: false , thStyle:  {width: '17%'}},
					{ key: 'location', label: 'Location', sortable: false , thStyle:  {width: '17%'}},
					{ key: 'red', label: 'Red', sortable: false , thStyle:  {width: '9%'}},
					{ key: 'blue', label: 'Blue', sortable: false , thStyle:  {width: '9%'}},
					{ key: 'green', label: 'Green', sortable: false , thStyle:  {width: '9%'}},
					{ key: 'black', label: 'Black', sortable: false , thStyle:  {width: '9%'}},
					{ key: 'duster', label: 'Duster', sortable: false , thStyle:  {width: '9%'}},
					{ key: 'remarks', label: 'Remarks', sortable: false , thStyle:  {width: '21%'}},
				],

				tableColumns2:[

					{ key: 'level', label: 'Building', sortable: false , thStyle:  {width: '17%'}},
					{ key: 'location', label: '', sortable: false , thStyle:  {width: '17%'}},
					{ key: 'total_red', label: 'Total Red', sortable: false , thStyle:  {width: '9%'}},
					{ key: 'total_blue', label: 'Total Blue', sortable: false , thStyle:  {width: '9%'}},
					{ key: 'total_green', label: 'Total Green', sortable: false , thStyle:  {width: '9%'}},
					{ key: 'total_black', label: 'Total Black', sortable: false , thStyle:  {width: '9%'}},
					{ key: 'total_duster', label: 'Total Duster', sortable: false , thStyle:  {width: '9%'}},
					{ key: 'remarks', label: '', sortable: false , thStyle:  {width: '21%'}},
				],
			}
		},

		directives: { Ripple },

		methods: {

			buildingDisable(id){
				var building_ids = [];
				for (var i = 0; i < this.checklistData.length; i++) {
					if (this.checklistData[i].building != '') {
					building_ids.push(this.checklistData[i].building);
					}
				}
				if (building_ids.indexOf(id) >= 0) {
					return true;
				}else{
					return false;
				}
			},

			disableCheckbox(i,type,index,color){
				this.checklistData[index].data[i].row_modify = true;

				if (type == 'on' && color == 'red') {
					this.checklistData[index].data[i].red = '';
					this.checklistData[index].data[i].red_disable   = this.checklistData[index].data[i].red_disable == true ? false : true;
				}
				else if (type == 'on' && color == 'blue') {
					this.checklistData[index].data[i].blue = '';
					this.checklistData[index].data[i].blue_disable   = this.checklistData[index].data[i].blue_disable == true ? false : true;
				}
				else if (type == 'on' && color == 'green') {
					this.checklistData[index].data[i].green = '';
					this.checklistData[index].data[i].green_disable   = this.checklistData[index].data[i].green_disable == true ? false : true;
				}
				else if (type == 'on' && color == 'black') {
					this.checklistData[index].data[i].black = '';
					this.checklistData[index].data[i].black_disable   = this.checklistData[index].data[i].black_disable == true ? false : true;
				}
				else if (type == 'on' && color == 'duster') {
					this.checklistData[index].data[i].duster = '';
					this.checklistData[index].data[i].duster_disable   = this.checklistData[index].data[i].duster_disable == true ? false : true;
				}

				if(this.checklistData[index].data[i].red_disable == true && this.checklistData[index].data[i].blue_disable == true && 
					this.checklistData[index].data[i].green_disable == true && this.checklistData[index].data[i].black_disable == true && 
					this.checklistData[index].data[i].duster_disable == true ) {
					this.checklistData[index].data[i].remarks = 'Office';
				} 
				else if ( (this.checklistData[index].data[i].remarks == 'Office' || this.checklistData[index].data[i].remarks == '') && 
					(this.checklistData[index].data[i].red_input_visible || 
					this.checklistData[index].data[i].blue_input_visible || 
					this.checklistData[index].data[i].green_input_visible || 
					this.checklistData[index].data[i].black_input_visible || 
					this.checklistData[index].data[i].duster_input_visible) ) {
					this.checklistData[index].data[i].remarks = 'Changed';
				}
			},
			
			clearChanged(i,index){
				if ( this.checklistData[index].data[i].remarks == 'Changed' && 
					(!this.checklistData[index].data[i].red_input_visible || !this.checklistData[index].data[i].blue_input_visible || 
					!this.checklistData[index].data[i].green_input_visible || !this.checklistData[index].data[i].black_input_visible || 
					!this.checklistData[index].data[i].duster_input_visible) ) {
					this.checklistData[index].data[i].remarks = '';
				}
			},

			inputVisibility(i,index,color){
				this.checklistData[index].data[i].row_modify = true;

				if (color == 'red_input') {
					this.checklistData[index].data[i].red_input = '';
					this.checklistData[index].data[i].red_input_visible   = this.checklistData[index].data[i].red_input_visible == true ? false : true;
					this.checklistData[index].data[i].red = 'no';
					this.checklistData[index].data[i].red_disable = false;
					this.totalRed();
				}
				else if (color == 'blue_input') {
					this.checklistData[index].data[i].blue_input = '';
					this.checklistData[index].data[i].blue_input_visible   = this.checklistData[index].data[i].blue_input_visible == true ? false : true;
					this.checklistData[index].data[i].blue = 'no';
					this.checklistData[index].data[i].blue_disable = false;
					this.totalBlue();
				}
				else if (color == 'green_input') {
					this.checklistData[index].data[i].green_input = '';
					this.checklistData[index].data[i].green_input_visible   = this.checklistData[index].data[i].green_input_visible == true ? false : true;
					this.checklistData[index].data[i].green = 'no';
					this.checklistData[index].data[i].green_disable = false;
					this.totalGreen();
				}
				else if (color == 'black_input') {
					this.checklistData[index].data[i].black_input = '';
					this.checklistData[index].data[i].black_input_visible   = this.checklistData[index].data[i].black_input_visible == true ? false : true;
					this.checklistData[index].data[i].black = 'no';
					this.checklistData[index].data[i].black_disable = false;
					this.totalBlack();
				}
				else if (color == 'duster_input') {
					this.checklistData[index].data[i].duster_input = '';
					this.checklistData[index].data[i].duster_input_visible   = this.checklistData[index].data[i].duster_input_visible == true ? false : true;
					this.checklistData[index].data[i].duster = 'no';
					this.checklistData[index].data[i].duster_disable = false;
					this.totalDuster();
				}
			},

			allSites(){
				return this.$store.dispatch(POST_API, {
					data:{
						role:this.$store.getters.currentUser.role,
					},
					api: '/api/all-sites'
				})
				.then(() => {
					if (this.$store.getters.containsErrors) {
						this.error_message = this.$store.getters.getErrors;
						this.showDismissibleAlert = true;
						window.scrollTo(0,0);
					} else {
						this.showDismissibleAlert = false;
						var data  = this.$store.getters.getResults.data;
						
						this.sites = data;

						if (this.sites.length == 1 && typeof this.$route.params.id == 'undefined') {
									
							this.site = this.sites[0]._id;
							this.siteChange();
						}
						return this.sites;
					}
				});
			},

			formSubmit(e){
				e.preventDefault();
				
				if (this.status == 'draft') {
					this.submitFunc();
				}else{
					this.decisionAlert('Are you sure want to submit this report ?')
					.then(result => {
						if (result.value) {
							this.submitFunc();						
						}
					})
				}
			},
			submitFunc(){
				return this.$store.dispatch(POST_API, {
					data:{
						id : this.id,
						date : this.date,
						site :this.site,
						checked_by : this.checked_by,
						shift : this.shift,
						checklistData : this.checklistData,
						status : this.status, 
						supervisor_signature : this.supervisor_signature, 
						start_time : this.start_time, 
						end_time : this.end_time, 
						form_id:this.$route.params.id,
            			approve_signature: this.supervisorSign,
            			approve_signature_time: this.approve_signature_time,
						approve_remark: this.approve_remark,
						role:this.$store.getters.currentUser.role,
					},
					api : '/api/submit-marker-pen'
				})
				.then(() => {
					if (this.$store.getters.containsErrors) {
						this.error_message        = this.$store.getters.getErrors;
						//this.showDismissibleAlert = true;

						//window.scrollTo(0,0);
						this.errorAlertCF()
					} else {
						this.showDismissibleAlert = false;

						var data = this.$store.getters.getResults.data;

						this.successAlert().then((result) => {

							if (this.sites.length == 1) {
								this.site = this.sites[0]._id;
							}else{
								this.site = '';
							}
							this.siteChange();

							if (data != null) {
								window.open(data, '_blank');
							}
							localStorage.setItem('routeCFCheck','no');
							Bus.$emit('counter_update');

							// this.$router.go(-1);
							if(this.$route.params.id){
                  				this.$router.push({name:'marker-pen-report'})
                			}else{
							this.$router.push({ name:'custom-forms' })
							}
						});
					}
				});
			},

			getLocationData(){
				// this.shift != '' ? localStorage.setItem('routeCFCheck','yes') : localStorage.setItem('routeCFCheck','no');
				localStorage.setItem('routeCFCheck','no');

				return this.$store.dispatch(POST_API, {
					data:{
						site:this.site != '' ? this.site : null,
						date:this.date,
						shift:this.shift != '' ? this.shift : null
					},
					api: '/api/marker-pen-locations-data'
				})
				.then(() => {
					if (this.$store.getters.containsErrors) {
						this.error_message = this.$store.getters.getErrors;
						this.showDismissibleAlert = true;
						window.scrollTo(0,0);
					} else {
						this.showDismissibleAlert = false;
						
						this.id = this.$store.getters.getResults.id;
						this.status = this.$store.getters.getResults.status;
						//this.shift = this.$store.getters.getResults.shift == undefined || this.$store.getters.getResults.shift == null ? '' : this.$store.getters.getResults.shift;
						this.checklistData = this.$store.getters.getResults.data;
						this.supervisor_signature = this.$store.getters.getResults.supervisor_signature;
						

						this.start_time = this.$store.getters.getResults.start_time;
						this.end_time = this.$store.getters.getResults.end_time;

						this.items2 = [];
						if (this.checklistData.length != 0){
							this.totalRed();
							this.totalGreen();
							this.totalBlue();
							this.totalBlack();
							this.totalDuster();
						}
			  
						return this.checklistData;
					}
				});
			},

			getShiftData(){
				return this.$store.dispatch(POST_API, {
					data:{
						site:this.site != '' ? this.site : null,
					},
					api: '/api/site-shifts'
				})
				.then(() => {
					if (this.$store.getters.containsErrors) {
						this.error_message = this.$store.getters.getErrors;
						this.showDismissibleAlert = true;
						window.scrollTo(0,0);
					} else {
						this.showDismissibleAlert = false;
						var data  = this.$store.getters.getResults.data;
						
						this.shifts = data;
						return this.shifts;
					}
				});
			},

			siteChange(){
				localStorage.setItem('routeCFCheck','no');
				this.checklistData = [];
				this.shifts = [];
				this.shift = '';

				if (this.site != '') {
					this.getShiftData();
					this.getBuildings();
					// this.getLocationData();
					//localStorage.setItem('routeCFCheck','yes');
				}else{
					//localStorage.setItem('routeCFCheck','no');
				}
			},

			buildingChange(id,i){
				this.checklistData[0].building != '' ? localStorage.setItem('routeCFCheck','yes') : localStorage.setItem('routeCFCheck','no');
				if (id != '') {
					return this.$store.dispatch(POST_API, {
					data:{
						building:id,
						site:this.site,
						
					},
					api: '/api/building-location-marker-pen-data'
					})
					.then(() => {
						if (this.$store.getters.containsErrors) {
							this.error_message = this.$store.getters.getErrors;
							this.showDismissibleAlert = true;
							window.scrollTo(0,0);
						} else {
							this.showDismissibleAlert = false;
							var data  = this.$store.getters.getResults.data;
							
							this.checklistData[i].data = data;

							if (this.checklistData.length != 0){
								this.totalRed();
								this.totalGreen();
								this.totalBlue();
								this.totalBlack();
								this.totalDuster();
							}

							return this.checklistData;
						}
					});

				}else{
					this.checklistData[i].data = [];
				}
			},

			addMore(){
				var order = 0;

				if (this.checklistData.length > 0) {
					var lastIndex = this.checklistData.length - 1;
					order = this.checklistData[lastIndex].order + 1;
				}

				var obj = {
					"building": "",
					"data": [],
					"order": order,
					total_red:'',
					total_blue:'',
					total_green:'',
					total_black:'',
					total_duster:'',

					"db_building":false,
					/* "supervisor_signature": {
						"image": null,
						"name": "",
						"default": "no"
					},
					"start_time": null,
					"end_time": null,
					"signature_time": null */
				};

				this.checklistData.push(obj);
			},

			removeData(index){
				this.checklistData.splice(index,1);
				this.items2.splice(index,1);
				if (this.checklistData.length == 0) {
					this.addMore();
				}
			},

			removeDBBuilding(index,item){
				var msg = 'Are you sure want to remove this building record?';

				this.decisionAlert(msg)
				.then(result => {
					if (result.value) {
						return this.$store.dispatch(POST_API, {
							data:{
								building_id : item.building,
								date : this.date,
								site :this.site,
								shift : this.shift,
							},
							api : "/api/remove-mp-db-building",
						})
						.then(() => {
							if (this.$store.getters.containsErrors) {
								this.error_message = this.$store.getters.getErrors;

								this.showAlert();
							} else {
								//localStorage.setItem('routeCFCheck','no');
								this.successAlert()
								.then(() => {

									this.checklistData.splice(index,1);
									if (this.checklistData.length == 0) {
									this.addMore();
									}

									var temp = [];

									this.checklistData.forEach((ele, ind) => {
										if(ele.building_name == this.items2[ind].building_name){
											temp.push(this.items2[ind])
										}
									});
									this.items2 = temp;
									this.$refs.totalTable.refresh();
									
								});
							}
						});
					}
				})
				.catch(err => {
					console.log(err);
				})
			},

			totalRed(){
				for (var i = 0; i < this.checklistData.length; i++) {
					if(this.checklistData.length != this.items2.length) {
						var obj = {
							building_name: this.checklistData[i].building_name,
							total_red: 0,
							total_blue: 0,
							total_green: 0,
							total_black: 0,
							total_duster: 0,
						};
						this.items2.push(obj);
					}

					this.items2[i].total_red = 0;

					for (var j = 0; j < this.checklistData[i].data.length; j++) {

						this.items2[i].building_name = this.checklistData[i].data[j].building_name
						if (this.checklistData[i].data[j].red_input != null && this.checklistData[i].data[j].red_input != '' && !isNaN(this.checklistData[i].data[j].red_input)) {
							this.items2[i].total_red = this.items2[i].total_red + parseInt(this.checklistData[i].data[j].red_input)
							this.checklistData[i].total_red = this.items2[i].total_red;
						}
					}
				}
			},
			
			totalBlue(){
				for (var i = 0; i < this.checklistData.length; i++) {
					if(this.checklistData.length != this.items2.length) {
						var obj = {
							building_name: this.checklistData[i].building_name,
							total_red: 0,
							total_blue: 0,
							total_green: 0,
							total_black: 0,
							total_duster: 0,
						};
						this.items2.push(obj);
					}

					this.items2[i].total_blue = 0;

					for (var j = 0; j < this.checklistData[i].data.length; j++) {

						if (this.checklistData[i].data[j].blue_input != null && this.checklistData[i].data[j].blue_input != '' && !isNaN(this.checklistData[i].data[j].blue_input)) {
							this.items2[i].total_blue = this.items2[i].total_blue + parseInt(this.checklistData[i].data[j].blue_input)
							this.checklistData[i].total_blue = this.items2[i].total_blue;
						}
					}
				}
			},
			
			totalGreen(){
				for (var i = 0; i < this.checklistData.length; i++) {
					if(this.checklistData.length != this.items2.length) {
						var obj = {
							building_name: this.checklistData[i].building_name,
							total_red: 0,
							total_blue: 0,
							total_green: 0,
							total_black: 0,
							total_duster: 0,
						};
						this.items2.push(obj);
					}

					this.items2[i].total_green = 0;

					for (var j = 0; j < this.checklistData[i].data.length; j++) {

						if (this.checklistData[i].data[j].green_input != null && this.checklistData[i].data[j].green_input != '' && !isNaN(this.checklistData[i].data[j].green_input)) {
							this.items2[i].total_green = this.items2[i].total_green + parseInt(this.checklistData[i].data[j].green_input)
							this.checklistData[i].total_green = this.items2[i].total_green;
						}
					}
				}
			},
			
			totalBlack(){
				for (var i = 0; i < this.checklistData.length; i++) {
					if(this.checklistData.length != this.items2.length) {
						var obj = {
							building_name: this.checklistData[i].building_name,
							total_red: 0,
							total_blue: 0,
							total_green: 0,
							total_black: 0,
							total_duster: 0,
						};
						this.items2.push(obj);
					}

					this.items2[i].total_black = 0;

					for (var j = 0; j < this.checklistData[i].data.length; j++) {

						if (this.checklistData[i].data[j].black_input != null && this.checklistData[i].data[j].black_input != '' && !isNaN(this.checklistData[i].data[j].black_input)) {
							this.items2[i].total_black = this.items2[i].total_black + parseInt(this.checklistData[i].data[j].black_input)
							this.checklistData[i].total_black = this.items2[i].total_black;
						}
					}
				}
			},
			
			totalDuster(){
				for (var i = 0; i < this.checklistData.length; i++) {
					if(this.checklistData.length != this.items2.length) {
						var obj = {
							building_name: this.checklistData[i].building_name,
							total_red: 0,
							total_blue: 0,
							total_green: 0,
							total_black: 0,
							total_duster: 0,
						};
						this.items2.push(obj);
					}

					this.items2[i].total_duster = 0;

					for (var j = 0; j < this.checklistData[i].data.length; j++) {

						if (this.checklistData[i].data[j].duster_input != null && this.checklistData[i].data[j].duster_input != '' && !isNaN(this.checklistData[i].data[j].duster_input)) {
							this.items2[i].total_duster = this.items2[i].total_duster + parseInt(this.checklistData[i].data[j].duster_input)
							this.checklistData[i].total_duster = this.items2[i].total_duster;
						}
					}
				}
			},
			
			getBuildings(){
				return this.$store.dispatch(POST_API, {
					data:{
						role:this.$store.getters.currentUser.role,
						site:this.site,
						form:'Marker Pens'
					},
					api: '/api/all-buildings'
				})
				.then(() => {
					if (this.$store.getters.containsErrors) {
						this.error_message = this.$store.getters.getErrors;
					} else {
						this.buildings    = this.$store.getters.getResults.data;
						return this.buildings;
					}
				});
			},
			
			openLocations(){
				this.$router.push({ name: 'hand-sanitizer-locations',params:{site_id:this.site != '' ? this.site : 'all-site',id:'all-building',form:'Marker_Pens'}})
			},

  			breadCrumb(){
	          	if(this.$route.params.id){

			        var item = [{
			          to:{name:'client-dashboard'},
			          text: 'Dashboard',
			        },{
			          to:null,
			          text: 'Reports',
			        },{
			          to:{name:'custom-forms-dashboard'},
			          text: 'Form Dashboard',
			      	},{
			          to:{name:'custom-forms-reports'},
			          text:'Custom Forms'
			        },{
			          to:{name:'marker-pen-report'},
			          text:'Marker Pens Report'
			        },{
			          to:null,
			          text:'Edit',
			          active:true
			        }];
			        return this.filterReportBreadCrum(item);

			    }else{
				      var item = [{
				        to:{name:'client-dashboard'},
				        text: 'Dashboard',
				      },{
				        to:{name:'custom-forms'},
				        text: 'Custom Forms',
				      },{
				        to:null,
				        text:'Marker Pens',   
				        active:true             
				      }];
			      return item;
			    }
    		},

			discardItem(){
				this.decisionAlert('Are you sure want to delete this record?')
				.then(result => {
					if (result.value) {
						return this.$store.dispatch(POST_API, {
							data:{
								id: this.id,
							},
							api:"/api/delete-marker-pen-report",
						})
						.then(() => {
							if (this.$store.getters.containsErrors) {
								this.error_message = this.$store.getters.getErrors;
								this.showAlert();
							} else {
								Bus.$emit('reset-signature');
								this.getLocationData();
								localStorage.setItem('routeCFCheck','no');
								this.successAlert()
                                .then(() => {
                                    
                                    // this.$router.go(-1);
									this.$router.push({ name:'custom-forms' })
                                });
							}
						});
					}
				})
				.catch(err => {
					console.log(err);
				})
			},
			goBack(){
				var msg = 'Do you want to Go back without saving your report?';
				this.decisionAlert(msg)
				.then(result => {
					if (result.value) {
						localStorage.setItem('routeCFCheck','no')
						// this.$router.go(-1);
					if(this.$route.params.id){
             		 this.$router.push({name:'marker-pen-report'})
            		}else{
						this.$router.push({ name:'custom-forms' })
					}
					}
				})
			},

			isNumber($event) {

				let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
				// only allow number 
				/*console.log($event.target.value.length);
				console.log($event.target.value,keyCode);*/
				if ($event.target.value.length >= 1) {
					$event.preventDefault();
				}else if(keyCode < 49 || keyCode > 57){
					$event.preventDefault();
				}
			},
			useDefault(){

		      return this.$store.dispatch(POST_API, {
		           data:{
		             id:this.$store.getters.currentUser._id
		           },
		           api: '/api/get-default-signature'
		        })
		        .then(() => {
		            if (this.$store.getters.containsErrors) {
		                this.error_message = this.$store.getters.getErrors;
		                this.showDismissibleAlert = true;
		                window.scrollTo(0,0);
		            } else {
		                this.showDismissibleAlert = false;
		                var data  = this.$store.getters.getResults.data;
		                
		                if (data == null || data.default_signature == null) {
		                  
		                  this.having_default = false;
		                  
		                }else{

		                  this.tempSign = {
		                    image : data.default_signature,
		                    name : 'signature.png',
		                  }

		                  this.having_default = true;
		                }
		            }
		        });
		    },
	    	removeSign(sign){
		        this.supervisor_signature.image = null;
		        this.supervisor_signature.name = '';
		        this.supervisor_signature.default = 'no';
		    },
		    uploadSign(){
		      
		      this.$refs['supervisorSInput'].click();
		      this.$refs['signatureModel'].hide();

		    },
		    openSignature(type){

		      this.approve_signature_time = moment(new Date()).tz('Asia/Singapore').format('DD MMM YYYY HH:mm');
		      
		      if (this.openedSignModel == null) {

		          if (this.having_default == true) {
		              this.show_default = true;
		          }else{
		            this.show_default = false;
		          }

		          this.$refs['signatureModel'].show();
		          this.openedSignModel = type;
		      }
		    },
		    hideSignature(){
		      this.openedSignModel = null;
		    },
		    clearSignature(){
		      this.$refs.signaturePad.clearSignature();
		    },
		    saveSignature(){
		      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
		      
		      if (isEmpty) {

		        Swal.fire({
		          position: 'center',
		          icon: 'warning',
		          title: 'Signature is required',
		          showConfirmButton: false,
		          timer: 1500
		        })

		      }else{
		        if (this.openedSignModel == 'supervisor') {

		            this.supervisor_signature.image = data;
		            this.supervisor_signature.name = 'signature.png';
		            this.supervisor_signature.type = 'supervisor';
		            this.supervisor_signature.default = 'no';
		            this.$refs['signatureModel'].hide();
		        }else{
		            this.supervisorSign.image = data;
		            this.supervisorSign.name = 'signature.png';
		            this.supervisorSign.type = 'supervisor';
		            this.supervisorSign.default = 'no';
		            this.status = 'approved';
		            this.submitFunc();
		        }
		      }
		    },
		    saveDefault(){

		      if (this.openedSignModel == 'supervisor') {
		          this.supervisor_signature = {
		            image : this.tempSign.image,
		            name : 'signature.png',
		            default:'yes'
		          }
		        }else{

		          this.supervisorSign = {
		            image : this.tempSign.image,
		            name : 'signature.png',
		            default:'yes',
		            type:'supervisor'
		          }
		          this.status = 'approved';
		          this.submitFunc();

		        }
		                 
		      
		        
		      this.$refs['signatureModel'].hide();
		    },
			getFormDetails(){
		      /*localStorage.setItem('routeCFCheck','yes');*/

		      return this.$store.dispatch(POST_API, {
		           data:{
		             id:this.$route.params.id
		           },
		           api: '/api/get-mp-report-detail'
		        })
		        .then(() => {
		            if (this.$store.getters.containsErrors) {
		                this.error_message = this.$store.getters.getErrors;
		                this.showDismissibleAlert = true;
		                window.scrollTo(0,0);
		            } else {
		                this.showDismissibleAlert = false;
		                var data  = this.$store.getters.getResults.data;
		                
		                this.id  = data._id;
		                this.date  = data.checked_date;
		                this.site  = data.site;
		                this.checked_by  = data.checked_by;
		                this.shift  = data.shift;
		                this.checklistData  = data.checkListData;
		                this.status = data.status;
		                this.supervisor_signature = {
		                    image   : data.supervisor_signature,
		                    name    : 'signature.png',
		                    type    : 'supervisor',
		                    default : 'yes'
		                };
		                this.start_time = data.start_time;
		                this.end_time = data.end_time;

		                var role = this.$store.getters.currentUser.role;

		                if (data.reject_to == role && ((data.level_one_user_role != role) && (data.level_two_user_role != role) && (data.level_three_user_role != role))) {
							//this.disabledOptions = false;
							this.ledit = true;
						} else if(((role == 'administrator') || (role == 'admin')) && ( (data.level_user_status != null) && (data.level_user_status.role == ''))){
							this.ledit = true;
						}

		                this.getShiftData();
		                this.getBuildings();
						this.totalRed();
						this.totalGreen();
						this.totalBlue();
						this.totalBlack();
						this.totalDuster();
		            }
		        });

		    },
		    disabledDate(date){
		      return date < moment(new Date(this.date)).subtract(1,'days').tz('Asia/Singapore');
		    },
		    checkdisabled(){
		      if (this.ledit == true) {
		        return false;
		      }else if(this.disabledOptions == true){
		        return true;
		      }else{
		        return false;
		      }
		    }
			
		},
		mounted(){
			this.useDefault();
			this.allSites();
			this.siteChange();
			// this.getLocationData();
			
			// to add supervisor sign
			Bus.$on('update-image', (data) => {
				this.supervisor_signature = data.data;
			})

			// to delete supervisor sign
			Bus.$on('remove-image', (data,index) => {
				this.supervisor_signature = {
					default:'no',
					name:'',
					image:null,
				};
			})
			if(this.$route.params.id){
      		this.disabledOptions = true;
      		this.getFormDetails();
    	}
		}

	}
</script>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-flatpicker.scss';
	@import '@core/scss/vue/libs/vue-select.scss';
</style>
